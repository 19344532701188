<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="充值" width="700px" @closed="form = null; auditForm = null;">
    <template v-if="form">
      <template v-if="form.id">
        <el-form ref="form" :model="form" label-width="68px" label-position="right" label-suffix=":">
          <div class="h">
            <div style="width: 50%;">
              <form-info-item label="供应商">{{form.entName}}</form-info-item>
              <form-info-item label="充值方式">{{paymentMethods[form.payType]}}</form-info-item>
              <form-info-item label="充值金额" inline>
                <b class="fs-large fc-s">{{$price(form.payMoney)}}</b>
              </form-info-item>

              <form-info-item label="手续费">
                <b>{{$price(form.payFee)}}</b>
              </form-info-item>
              <form-info-item label="充值日期">{{new Date(form.payTime).format('yyyy/MM/dd')}}</form-info-item>
              <form-info-item label="付款日期" v-if="form.payType ===1">{{form.offlinePayTime?new Date(form.offlinePayTime).format('yyyy/MM/dd'):''}}</form-info-item>
              <form-info-item label="付款人" v-if="form.payType ===1">{{form.payer}}</form-info-item>
              <form-info-item label="支付账户" v-if="form.payerAccount">{{form.payerAccount}}</form-info-item>
              <form-info-item label="收款账户" v-if="form.payeeAccount">{{form.payeeAccount}}</form-info-item>
              <form-info-item label="到账日期" v-if="form.status === 1">{{new Date(form.checkinTime).format('yyyy/MM/dd')}}</form-info-item>
            </div>
            <div class="flex">
              <form-info-item label="充值单号">{{form.code}}</form-info-item>
              <form-info-item label="当前状态">
                <dot same :type="status[form.payType][form.status].type">{{status[form.payType][form.status].label}}</dot>
              </form-info-item>
              <template v-if="form.status !== 0">
                <form-info-item label="审批时间">{{new Date(form.checkTime).format()}}</form-info-item>
                <form-info-item label="审批意见">{{form.checkInfo}}</form-info-item>
              </template>
              <template v-if="form.payType ===1 ">
                <form-info-item label="付款回单">
                  <file-uploader :entity-id="form.fileId" folder="pay/receipt" multiple readonly empty-text="没有上传付款回单" />
                </form-info-item>
                <form-info-item label="授权书">
                  <file-uploader :entity-id="form.fileId" folder="pay/auth" multiple readonly empty-text="没有上传授权书" />
                </form-info-item>
              </template>
            </div>
          </div>

          <form-info-item label="备注">{{form.info}}</form-info-item>
        </el-form>        
      </template>
      <template v-else>
        <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="84px" label-suffix=":">
          <div class="h">
            <el-form-item prop="entId" label="供应商">
              <quick-select v-model="form.entId" :label.sync="form.entName" filterable url="api/purchase/seller" placeholder="选择供应商" value-field="belongTo" display-field="belongToName" auto-select-first-option @change="handleSellerChange" class="filter-item" style="width: 230px;" />
            </el-form-item>
            <el-form-item prop="payType" label="充值方式" label-width="116px">
              <el-radio-group v-model="form.payType" @change="form.payChannel = form.payType === 0 ? 'thirdPay' : null">
                <el-radio-button :label="0" v-if="enablePayType !== 'offline'">在线充值</el-radio-button>
                <el-radio-button :label="1" v-if="enablePayType !== 'online'">线下充值</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </div>
          <template v-if="form.payType === 1">
            <div class="h">
              <!-- 增加附件上传字段“付款回单”“授权书”，“付款人”“付款日期”字段，收款账户可选或者默认的金可儿收款账号 -->
              <el-form-item prop="payMoney" label="充值金额">
                <price-input v-model="form.payMoney" :min="0" :step-ratio="10000" style="width: 230px" />
              </el-form-item>
              <el-form-item prop="payTime" label="充值日期" label-width="116px">
                <el-date-picker type="date" v-model="form.payTime" value-format="timestamp" :clearable="false" style="width: 230px" />
              </el-form-item>
            </div>
            <div class="h">
              <el-form-item prop="payer" label="付款人">
                <el-input v-model="form.payer" maxlength="20" style="width: 230px"/>
              </el-form-item>
              <el-form-item prop="offlinePayTime" label="付款日期" label-width="116px">
                <el-date-picker type="date" v-model="form.offlinePayTime" value-format="timestamp" :clearable="false" style="width: 230px" />
              </el-form-item>
            </div>
            <el-form-item key="inpay" prop="payerAccount" label="支付账户">
              <div class="h">
                <el-input v-model="form.payerAccount" maxlength="80" clearable class="flex" />
                <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/account" :popper-width="800" :show-index="false" @change="handlePayerAccountChange" style="margin-left: 6px; margin-top: -1px;">
                  <el-table-column label="账户类型" :formatter="r => {return formTypes[r.accountType];}" />
                  <el-table-column prop="accountCode" label="账号" />
                  <el-table-column prop="accountName" label="账户" />
                  <el-table-column prop="bankName" label="银行名称" />
                </datagrid-picker>
              </div>
            </el-form-item>
            <el-form-item key="inrec" prop="payeeAccount" label="收款账户">
              <div class="h">
                <el-input v-model="form.payeeAccount" maxlength="80" clearable class="flex" />
                <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/account" :params="{entId:form.entId,sort: 'createAt,desc'}" :popper-width="800" :show-index="false" @change="handlePayeeAccountChange" v-if="form.entId" style="margin-left: 6px; margin-top: -1px;">
                  <el-table-column label="账户类型" :formatter="r => {return formTypes[r.accountType];}" />
                  <el-table-column prop="accountCode" label="账号" />
                  <el-table-column prop="accountName" label="账户" />
                  <el-table-column prop="bankName" label="银行名称" />
                </datagrid-picker>
              </div>
            </el-form-item>
            <div class="h">
              <el-form-item label="付款回单" prop="_pics">
                <file-uploader v-model="form._pics" :entity-id="form.fileId" folder="pay/receipt" multiple required />
              </el-form-item>
              <el-form-item label="授权书" >
                <file-uploader :entity-id="form.fileId" folder="pay/auth" multiple />
              </el-form-item>
            </div>
          </template>
          <template v-else>
            <el-form-item prop="payMoney" label="充值金额">
              <price-input v-model="form.payMoney" :min="0" :step-ratio="10000" style="width: 230px" />
            </el-form-item>
            <el-form-item label="实付金额">
              <div class="h c">
                <b class="fs-large fc-e">{{$price(form.payMoney * (1 + feeRate))}}</b>
                <span class="fc-g padding-0-10">支付金额：{{$price(form.payMoney)}} + 手续费用：{{$price(form.payMoney * feeRate)}}</span>
              </div>
            </el-form-item>
            <el-form-item label="支付方式">
              <div class="h c">
                <el-select v-model="form.payChannel" :clearable="false" style="width: 230px;">
                  <el-option value="thirdPay" label="使用第三方支付进行支付" />
                  <el-option value="unionPay" label="使用银联进行支付" />
                </el-select>
                <div class="padding-0-10 fc-g fs-mini">{{form.payChannel === 'thirdPay' ? '请使用微信或支付宝扫描打开的支付页面中的二维码进行支付' : '请按照打开的支付页面引导流程进行支付'}}</div>
              </div>
            </el-form-item>
          </template>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="form.info" :maxlength="200" :rows="3" resize="none"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="dialog = false">取消</el-button>
          <el-button type="primary" :loading="saving" :disabled="!form.payMoney" @click="doSubmit" v-if="form.payType === 1">提交</el-button>
          <el-button type="primary" :loading="saving" :disabled="!form.payMoney" @click="doPay" v-else>立即支付</el-button>
        </div>
      </template>
    </template>
  </el-dialog>
</template>
      
      
<script>
import { credit } from "@/api/payForm";
import request from "@/utils/request";

export default {
  data() {
    return {
      saving: false,
      dialog: false,
      form: null,
      paymentMethods: ["在线支付", "线下支付"],
      formTypes: ["银行卡", "支付宝", "微信"],
      enablePayType: "all",
      feeRate: 0,
      rules: {
        entId: [{ required: true, message: "请选择供应商" }],
        payType: [{ required: true, message: "请选择支付方式" }],
        payTime: [{ required: true, message: "请选择收款日期" }],
        payer:[{required:true,message:"请输入付款人"}],
        offlinePayTime:[{required:true,message:"请选择付款日期"}],
        payMoney: [{ required: true, message: "请填写收款金额" }],
        payerAccount: [{ required: true, message: "请填写支付方的账户" }],
        payeeAccount: [{ required: true, message: "请填写收款方的账户" }],
        _pics: [{ required: true, message: "付款回单为必填项" }]
      },
      status: [
        [
          {
            type: "info",
            label: "待支付"
          },
          {
            type: "success",
            label: "支付成功"
          },
          {
            type: "danger",
            label: "支付失败"
          }
        ],
        [
          {
            type: "info",
            label: "待审批"
          },
          {
            type: "success",
            label: "已通过"
          },
          {
            type: "danger",
            label: "未通过"
          }
        ]
      ]
    };
  },
  methods: {
    loadFeeRate() {
      if (this.form.entId) {
        request({
          url: "api/pay/rate",
          method: "get",
          params: {
            payMoney: 10000,
            entId: this.form.entId
          }
        }).then(res => {
          this.feeRate = res.feeRate * res.feeDistributorRate;
        });
      } else {
        this.feeRate = 0;
      }
    },
    getEnablePayType() {
      if (this.form.entId) {
        request({
          url: "api/distributor/otherPayType/" + this.form.entId,
          method: "get"
        }).then(res => {
          this.enablePayType = res;
          if (res === "offline") {
            this.form.payType = 1;
          } else if (res === "online") {
            this.form.payType = 0;
          }
        });
      }
    },
    handleSellerChange() {
      this.getEnablePayType();
      this.loadFeeRate();
    },
    doPay() {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          this.$parent.doOnlinePay(this.form);
          this.dialog = false;
        });
    },
    doSubmit() {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          this.$confirm(
            "充值信息提交后将不能修改，请确认所填信息无误。<br />确定要进行提交吗？",
            "操作确认",
            {
              type: "warning",
              dangerouslyUseHTMLString: true
            }
          ).then(_ => {
            this.saving = true;
            credit(this.form)
              .then(res => {
                this.$notify({
                  title: "充值信息提交成功",
                  type: "success",
                  duration: 2500
                });
                this.dialog = false;
                this.$parent.reload();
              })
              .finally(_ => {
                this.saving = false;
              });
          });
        });
    },
    resetForm(form) {
      this.form = form || {
        entId: null,
        entName: "",
        payType: 1,
        payerAccount: "",
        payeeAccount: "",
        payTime: new Date().getTime(),
        payMoney: 0,
        inOutType: "in",
        payChannel: null,
        relationFormType: 3,
        relationFormId: null,
        relationFormCode: null,
        info: "",
        status: 0,
        fileId:this.$uuid()
      };
      this.dialog = true;
    },
    handlePayerAccountChange(newVal, oldVal, row) {
      if (row.accountType === 0) {
        this.form.payerAccount = `${row.bankName} (${row.accountName}, ${row.accountCode})`;
      } else {
        this.form.payerAccount = `${this.formTypes[row.accountType]} (${
          row.accountCode
        })`;
      }
    },
    handlePayeeAccountChange(newVal, oldVal, row) {
      if (row.accountType === 0) {
        this.form.payeeAccount = `${row.bankName} (${row.accountName}, ${row.accountCode})`;
      } else {
        this.form.payeeAccount = `${this.formTypes[row.accountType]} (${
          row.accountCode
        })`;
      }
    }
  }
};
</script>